<template>
	<div class="input-item-block">
		<el-select
			v-if="metaData.type === 'select'"
			v-model="value"
			:multiple="metaData.multiple"
			collapse-tags
			:placeholder="metaData.placeholder"
			:disabled="!list.length"
			@focus="hasError = false"
		>
			<el-option
				v-for="item in list"
				:key="`form-item-${metaData.prop}-${item.id}`"
				:label="item[metaData.selectOptionLabel]"
				:value="item.id"
			>
			</el-option>
		</el-select>

		<el-input
			v-else
			v-model="value"
			:placeholder="metaData.placeholder"
			@focus="hasError = false"
		/>
		<div v-if="hasError" class="el-form-item__error">
			{{ metaData.errorText }}
		</div>
	</div>
</template>

<script>
import { getList } from '@/helpers';

export default {
	props: {
		rowData: {
			type: Object,
			required: true
		},
		isActive: Boolean
	},

	data() {
		return {
			value: '',
			hasError: false
		};
	},

	computed: {
		column() {
			return this.rowData.column;
		},
		metaData() {
			return this.column.meta.formFieldData;
		},
		list() {
			return getList[this.metaData.listName]() || [];
		}
	},

	created() {
		const { row } = this.rowData;
		// console.log(this.column.prop, row[this.column.prop])
		this.value = row[this.column.prop];
	},

	methods: {
		cleanBlock() {
			this.value = '';
		},

		validateBlock() {
			try {
				if (this.isActive) {
					let payload = { isValid: null, data: {}, itemId: this.rowData.row.id };
					const { required, prop } = this.metaData;

					if (required) {
						payload.isValid = this.value ? true : false;
					} else {
						payload.isValid = true;
					}

					if (!payload.isValid) {
						this.hasError = true;
					}

					payload.data[prop] = this.value;
					// console.log(payload)
					this.$emit('fieldReady', payload);
				}
			} catch (e) {
				console.warn(e);
			}
		}
	}
};
</script>
